import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container } from "../components/Sections"
import { SectionHeading } from "../components/Sections"
import { RevealFadeIn } from '../components/Reveal'
import Contact from "../components/Person"

export const TrainingTrialTemplate = ({
  heading,
  subheading,
  image,
  contact,
  content,
  className,
}) => {
  return (
    <RevealFadeIn>
      <Container className={className ? className : ''}>
        <SectionHeading>{heading}</SectionHeading>
        <div className="flex flex-col md:flex-row justify-between mt-8">
          <div
            className="prose dark:prose-invert sm:pr-8 mb-4 pb-4 sm:mb-0 sm:mr-4 text-left leading-relaxed text-lg"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <Contact uuid={contact} />
        </div>
      </Container>
    </RevealFadeIn>
  )
}

const TrainingTrial = ({className}) => {
  return (
    <StaticQuery
      query={graphql`
        query TrainingTrial {
          allMarkdownRemark(
            filter: { fields: { slug: { eq: "/.data/block/training-trial/" } } }
          ) {
            nodes {
              html
              frontmatter {
                heading
                subheading
                uuid
                image {
                  childImageSharp {
                    gatsbyImageData(width: 150, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { frontmatter: fm, html } = data.allMarkdownRemark.nodes[0]
        return (
          <TrainingTrialTemplate
            className={className ? className : ''}
            heading={fm.heading}
            subheading={fm.subheading}
            image={fm.image}
            contact={fm.uuid}
            content={html}
          />
        )
      }}
    />
  )
}

export default TrainingTrial
