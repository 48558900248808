import React from "react"
import { Content } from "../components/Content"
import { Container, Hero, SectionHeading } from "../components/Sections"
import Header from "../components/Header"
import { RevealFadeIn } from '../components/Reveal'
import TrainingTrial from "../components/TrainingTrial"

function tableRow({ name, price }, idx) {
  return (
    <tr className="even:bg-gray-100 dark:even:bg-gray-600 odd:bg-none" key={idx}>
      <td className="p-2">{name}</td>
      <td className="text-right p-2">{price}</td>
    </tr>
  )
}

const MemberPageTemplate = ({ heading, subheading, image, intro, prices, terms }) => {
  return (
    <div>
      <Header heading={heading} subheading={subheading} />
      <Hero image={image}/>
      <Container>
        <Content html={intro} />
      </Container>
      <TrainingTrial className=""/>
      <Container className="">
        <SectionHeading>{prices.heading}</SectionHeading>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-8">
          <RevealFadeIn>
            <div className="py-4 md:py-0">
              <h3 className="text-lg sm:text-xl lg:text-2xl py-4 dark:text-gray-300">Einzelmitgliedschaft</h3>
              <table className="prose dark:prose-invert -ml-2 w-[calc(100%+1rem)]">
                <tbody>{prices.single.map(tableRow)}</tbody>
              </table>
            </div>
          </RevealFadeIn>
          <RevealFadeIn>
            <div className="py-4 md:py-0">
              <h3 className="text-lg sm:text-xl lg:text-2xl py-4 dark:text-gray-300">Familienmitgliedschaft</h3>
              <table className="prose dark:prose-invert -ml-2 w-[calc(100%+1rem)]">
                <tbody>
                  {prices.family.map(tableRow)}
                  <tr className="even:bg-gray-100 odd:bg-none">
                    <td colSpan="2" className="p-2">
                      {prices.familynote}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </RevealFadeIn>
          {terms.map((term, idx) => (
            <RevealFadeIn key={idx}>
              <div className="py-4 md:py-0">
                <h3 className="text-lg sm:text-xl lg:text-2xl py-4 dark:text-gray-300">{term.heading}</h3>
                <div
                  className="prose dark:prose-invert md:text-lg"
                  dangerouslySetInnerHTML={{ __html: term.text.html }}
                />
              </div>
            </RevealFadeIn>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default MemberPageTemplate
