import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import MemberPageTemplate from "./MemberPageTemplate"

const MemberPage = ({ data }) => {
  const { markdownRemark: membership } = data
  const { frontmatter: fm } = membership

  return (
    <>
      <MyHelmet title={fm.title} description={fm.subheading} />
      <MemberPageTemplate
        heading={fm.heading}
        subheading={fm.subheading}
        image={fm.image}
        intro={fm.text1.html}
        prices={fm.prices}
        terms={fm.terms}
      />
    </>
  )
}

export default MemberPage

export const clubPageQuery = graphql`
  query MemberPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subheading
        image {
          childImageSharp {
            gatsbyImageData(width: 1216, placeholder: BLURRED)
          }
        }
        text1 {
          html
        }
        prices {
          heading
          single {
            name
            price
          }
          family {
            name
            price
          }
          familynote
        }
        terms {
          heading
          text {
            html
          }
        }
      }
    }
  }
`
